import React from "react"
import SbEditable from "storyblok-react"
import styled from "styled-components"
import LinkBlok from "src/storyblok/Components/LinkBlok"
import LinkComponent from "src/components/Link/LinkComponent"
import Section from "src/components/UI/Layout/Section"
import Container from "src/components/UI/Layout/Grid/Container"
import Row from "src/components/UI/Layout/Grid/Row"
import Column from "src/components/UI/Layout/Grid/Column"
import { Facebook } from "src/components/UI/Elements/Icon"
import { Instagram } from "src/components/UI/Elements/Icon"
import Flex from "src/components/UI/Layout/Flex"
import Box from "src/components/UI/Layout/Box"
import menujson from "src/json/menu.json"

const Wrapper = styled(Section)`
  opacity: ${({ hidden }) => (hidden ? 0.54 : 1)};
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 2;
  background-color: black;
`

const Navigation = styled.nav`
  text-align: center;
`

const Menu = ({ blok, isEditor }) => {
  if (!isEditor) {
    blok = menujson && menujson.content ? menujson.content : null
  }

  const { hidden, navigation, facebook, instagram } = blok ? blok : null

  return !hidden || isEditor ? (
    <SbEditable content={blok}>
      <Wrapper hidden={hidden ? hidden : 0} py={[4, null, null, 4]}>
        <Container>
          <Row
            justifyContent="space-between"
            alignItems="center"
            flexDirection="row"
          >
            <Column width={[1, null, null, 2 / 12]} />
            <Column width={[12 / 12, null, null, 8 / 12]}>
              <Row justifyContent="center">
                <Column>
                  <Navigation>
                    {navigation && navigation.length > 0
                      ? navigation.map((blok, index) => (
                          <LinkBlok
                            key={"menu-item-" + index}
                            blok={blok}
                            isEditor={isEditor}
                          />
                        ))
                      : null}
                  </Navigation>
                </Column>
              </Row>
            </Column>
            <Column width={[1, null, null, 2 / 12]}>
              <Box display={["none", null, null, "block"]}>
                <Flex justifyContent="center" flexDirection="row">
                  {facebook && (
                    <Box marginRight={2}>
                      <LinkComponent
                        blok={{
                          link: {
                            url: facebook,
                            cached_url: facebook,
                            linktype: "url",
                          },
                          text: "Facebook",
                        }}
                        isEditor={isEditor}
                      >
                        <Facebook width="40" height="40" color="white" />
                      </LinkComponent>
                    </Box>
                  )}
                  {instagram && (
                    <Box>
                      <LinkComponent
                        blok={{
                          link: {
                            url: instagram,
                            cached_url: instagram,
                            linktype: "url",
                          },
                          text: "Instagram",
                        }}
                        isEditor={isEditor}
                      >
                        <Instagram width="40" height="40" color="white" />
                      </LinkComponent>
                    </Box>
                  )}
                </Flex>
              </Box>
            </Column>
          </Row>
        </Container>
      </Wrapper>
    </SbEditable>
  ) : null
}

export default Menu
