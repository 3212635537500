import React from "react"
import Section from "src/components/UI/Layout/Section"
import Container from "src/components/UI/Layout/Grid/Container"
import Row from "src/components/UI/Layout/Grid/Row"
import Column from "src/components/UI/Layout/Grid/Column"
import Text from "src/components/UI/Typography/Text"
import H2 from "src/components/UI/Typography/H2"
import Mailchimp from "./Mailchimp"
import Box from "src/components/UI/Layout/Box"
import SbEditable from "storyblok-react"

const Newsletter = ({ blok }) => (
  <SbEditable content={blok}>
    <Section marginY={[6, null, null, 10]} id="newsletter">
      <Container>
        <Row flexDirection="column" alignItems="center">
          <Column width={[12 / 12, null, null, 6 / 12]}>
            <H2 mb={[6, null, null, 10]}>Nyhetsbrev</H2>
            <Box mb={3}>
              <Text textAlign="center" is="p" fontWeight="600">
                Fyll i din epost för att vara först med information kring nya
                datum och biljetter.
              </Text>
            </Box>
            <Mailchimp />
          </Column>
        </Row>
      </Container>
    </Section>
  </SbEditable>
)

export default Newsletter
