import styled from "styled-components"
import Flex from "src/components/UI/Layout/Flex"

const Column = styled(Flex)``

Column.defaultProps = {
  position: "relative",
  width: "100%",
  minHeight: "1px",
  flexDirection: "column",
  px: [1, null, null, 2],
}

export default Column
