import React from "react"
import styled from "styled-components"
import SbEditable from "storyblok-react"
import Section from "src/components/UI/Layout/Section"
import Container from "src/components/UI/Layout/Grid/Container"
import Row from "src/components/UI/Layout/Grid/Row"
import Column from "src/components/UI/Layout/Grid/Column"
import Markdown from "src/storyblok/Components/Markdown"
import H2 from "src/components/UI/Typography/H2"
import Flex from "src/components/UI/Layout/Flex"
import Box from "src/components/UI/Layout/Box"
import Components from "src/storyblok/Components.js"
const Wrapper = styled(Section)`
  opacity: ${({ hidden }) => (hidden ? 0.54 : 1)};
`

const StyledMarkdown = styled.div`
  font-size: 18px;

  @media (min-width: 768px) {
    font-size: 22px;
  }
`

const Members = ({ blok, isEditor }) => {
  const { title, text, hidden, members } = blok
  return !hidden || isEditor ? (
    <SbEditable content={blok}>
      <Wrapper
        hidden={hidden ? hidden : null}
        justifyContent="center"
        alignItems="center"
        marginY={[6, null, null, 10]}
        id="information"
      >
        <Container>
          <Row justifyContent="center">
            {(title || text) && (
              <Column width={[1, null, null, 10 / 12]}>
                <Box textAlign="center">
                  {title && <H2 mb={[6, null, null, 10]}>{title}</H2>}
                  {text && (
                    <StyledMarkdown>
                      <Markdown blok={{ text: text }} />
                    </StyledMarkdown>
                  )}
                </Box>
              </Column>
            )}
          </Row>

          <Row justifyContent="center">
            <Column width={[1, null, null, 10 / 12]}>
              {members && members.length > 0 && (
                <Row flexDirection={["row", null, null, "row"]}>
                  {members.map(blok => {
                    return (
                      <Column
                        key={blok._uid}
                        width={[1 / 2, null, null, 3 / 12]}
                      >
                        <Box marginBottom={[4]}>
                          {React.createElement(Components(blok.component), {
                            blok: blok,
                          })}
                        </Box>
                      </Column>
                    )
                  })}
                </Row>
              )}
            </Column>
          </Row>
        </Container>
      </Wrapper>
    </SbEditable>
  ) : null
}

export default Members
