import React from "react"
import SbEditable from "storyblok-react"
import Container from "src/components/UI/Layout/Grid/Container"
import Row from "src/components/UI/Layout/Grid/Row"
import Column from "src/components/UI/Layout/Grid/Column"
import H2 from "src/components/UI/Typography/H2"
import Image from "src/components/Image/Image"

const Hero = ({ blok }) => {
  const { title, logotype, tagline } = blok
  return (
    <SbEditable content={blok}>
      <Container>
        <Row justifyContent="center" alignItems="center" flexDirection="column">
          {logotype && (
            <Column
              width={[9 / 12, null, null, 6 / 12]}
              mb={[2, null, null, 2]}
            >
              <Image src={logotype} alt={"Logotype"} size="1920" />
            </Column>
          )}
          {tagline && (
            <Column
              width={[11 / 12, null, null, 7 / 12]}
              mb={[2, null, null, 2]}
            >
              <Image src={tagline} alt={"Logotype"} size="1920" />
            </Column>
          )}
          {title && (
            <Column width={[12 / 12, null, null, 10 / 12]}>
              <H2 as="span">{title}</H2>
            </Column>
          )}
        </Row>
      </Container>
    </SbEditable>
  )
}

export default Hero
