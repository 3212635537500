// Page types
import PageHome from "./Pages/PageHome"
import PageDefault from "./Pages/PageDefault"

// Global Modules
import GlobalSeo from "./GlobalModules/GlobalSeo"
import Menu from "./GlobalModules/Menu"

// Content Components
import Hero from "./ContentComponents/Hero"
import Dates from "./ContentComponents/Dates"
import Faq from "./ContentComponents/Faq"
import Information from "./ContentComponents/Information"
import Newsletter from "./ContentComponents/Newsletter/Newsletter"
import Content from "./ContentComponents/Content"
import Members from "./ContentComponents/Members"
import Gallery from "./ContentComponents/Gallery"

// Components
import TicketLink from "./Components/TicketLink"
import LinkBlok from "./Components/LinkBlok"
import ImageBlok from "./Components/ImageBlok"
import Member from "./Components/Member"
import ComponentNotFound from "./ComponentNotFound"

const ComponentList = {
  //   Page types
  page_home: PageHome,
  page_default: PageDefault,

  // Global Modules
  global_seo: GlobalSeo,
  menu: Menu,

  // Content Components
  hero: Hero,
  dates: Dates,
  faq: Faq,
  information: Information,
  newsletter: Newsletter,
  content: Content,
  members: Members,
  gallery: Gallery,

  // Components
  ticket_link: TicketLink,
  link: LinkBlok,
  image: ImageBlok,
  member: Member,
}

const Components = type => {
  if (typeof ComponentList[type] === "undefined") {
    return ComponentNotFound
  }
  return ComponentList[type]
}

export default Components
