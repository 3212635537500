import React from "react"
import styled from "styled-components"
import SbEditable from "storyblok-react"
import Section from "src/components/UI/Layout/Section"
import Container from "src/components/UI/Layout/Grid/Container"
import Row from "src/components/UI/Layout/Grid/Row"
import Column from "src/components/UI/Layout/Grid/Column"
import H2 from "src/components/UI/Typography/H2"
import Toggle from "src/storyblok/Components/Toggle"

const Wrapper = styled(Section)`
  opacity: ${({ hidden }) => (hidden ? 0.54 : 1)};
`

const Faq = ({ blok, isEditor }) => {
  const { title, items, hidden } = blok
  return !hidden || isEditor ? (
    <SbEditable content={blok}>
      <Wrapper
        hidden={hidden ? hidden : null}
        justifyContent="center"
        alignItems="center"
        marginY={[6, null, null, 10]}
        id="faq"
      >
        <Container>
          <Row justifyContent="center">
            {title && (
              <Column width={[12 / 12, null, null, 10 / 12]}>
                <H2 mb={[6, null, null, 10]}>{title}</H2>
              </Column>
            )}

            {items && items.length > 0 && (
              <Column width={[12 / 12, null, null, 10 / 12]}>
                <Row>
                  {items.map(blok => (
                    <Column
                      key={blok._uid}
                      width={[12 / 12, null, null, 6 / 12]}
                    >
                      <Toggle blok={blok} isEditor={isEditor} />
                    </Column>
                  ))}
                </Row>
              </Column>
            )}
          </Row>
        </Container>
      </Wrapper>
    </SbEditable>
  ) : null
}

export default Faq
