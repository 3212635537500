import React from "react"
import SbEditable from "storyblok-react"
import styled from "styled-components"
import ReactMarkdown from "react-markdown/with-html"
import remarkBreaks from "remark-breaks"

const StyledMarkdown = styled.div`
  * {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  strong {
    font-weight: bold;
  }
`

const linkTarget = (href = "") => {
  let returnTarget

  const isExternal =
    href.substr(0, 4) === "http" ||
    href.substr(0, 6) === "mailto" ||
    href.substr(0, 3) === "tel" ||
    href.substr(0, 9) === "data:text"

  if (isExternal) {
    returnTarget = "_blank"
  }

  return returnTarget
}

const LinkRenderer = props => {
  const { children, href } = props

  const target = linkTarget(href)

  return (
    <a
      href={href}
      target={target}
      rel={target === "_blank" ? "noopener noreferrer" : ""}
    >
      {children}
    </a>
  )
}

const Markdown = ({ blok, dark, escapeHtml = false, noParagraphs = false }) => {
  const plugins = [remarkBreaks]
  const className = "markdown"
  const source = blok.text

  const markdownProps = {
    className,
    plugins,
    source,
    noParagraphs,
    escapeHtml,
  }
  return (
    <SbEditable content={blok}>
      <StyledMarkdown>
        <ReactMarkdown renderers={{ link: LinkRenderer }} {...markdownProps} />
      </StyledMarkdown>
    </SbEditable>
  )
}

export default Markdown
