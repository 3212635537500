import React from "react"
import SbEditable from "storyblok-react"
import styled from "styled-components"
import Flex from "src/components/UI/Layout/Flex"
import Box from "src/components/UI/Layout/Box"
import Label from "src/components/UI/Typography/Label"
import { Arrow } from "src/components/UI/Elements/Icon"

const Wrapper = styled(Flex)`
  opacity: ${props => (props.unavailable ? "0.54" : "1")};

  .icon {
    transform: translateX(0);
    transition: transform 0.16s ease;
  }

  &:hover {
    .icon {
      transform: translateX(4px);
    }
  }
`

const StyledLink = styled.a`
  text-decoration: none !important;
`

const Item = styled(Label)`
  text-transform: uppercase;
  text-decoration: ${props => (props.unavailable ? "line-through" : "none")};

  &.ticket {
    text-decoration: underline !important;
  }
`

const Content = ({ blok }) => {
  const { title, time, link, status } = blok
  const unavailable = status && status === "unavailable" ? 1 : null
  const few = status && status === "few" ? 1 : null
  return (
    <Wrapper
      flexDirection="row"
      py={[2, null, null, 3]}
      alignItems="center"
      unavailable={unavailable}
    >
      <Flex
        width={("auto", null, null, 6 / 12)}
        flexDirection={["column", null, null, "row"]}
        alignItems="center"
      >
        <Box width={[12 / 12, null, null, 6 / 12]}>
          {title && <Item unavailable={unavailable}>{title}</Item>}
        </Box>
        <Box width={[12 / 12, null, null, 6 / 12]}>
          {time && <Item unavailable={unavailable}>{time}</Item>}
        </Box>
      </Flex>
      {!unavailable && !few && link && (
        <Flex ml="auto" alignItems="center">
          <Item mr={[1, null, null, 2]} className="ticket">
            Köp biljetter
          </Item>
          <Arrow className="icon" width="28" height="22" color="white" />
        </Flex>
      )}
      {few && link && (
        <Flex ml="auto" alignItems="center">
          <Item mr={[1, null, null, 2]} className="ticket">
            Fåtal kvar
          </Item>
          <Arrow className="icon" width="28" height="22" color="white" />
        </Flex>
      )}
      {unavailable && (
        <Flex ml="auto" alignItems="center">
          <Item mr={[1, null, null, 2]} pr={"28px"}>
            Utsålt
          </Item>
        </Flex>
      )}
    </Wrapper>
  )
}

const TicketLink = ({ blok, isEditor }) => {
  const { title, link, unavailable } = blok
  return (
    <SbEditable content={blok}>
      {!unavailable && !isEditor && link ? (
        <StyledLink
          href={link}
          title={title}
          rel="noopener noreferrer"
          target="_blank"
        >
          <Content blok={blok} />
        </StyledLink>
      ) : (
        <Content blok={blok} />
      )}
    </SbEditable>
  )
}

export default TicketLink
