/* eslint-disable */
import React, { Component } from "react"
import MailchimpSubscribe from "react-mailchimp-subscribe"
import Flex from "src/components/UI/Layout/Flex"
import Text from "src/components/UI/Typography/Text"
import Box from "src/components/UI/Layout/Box"
import styled from "styled-components"

const Input = styled("input")`
  ${props => props.theme.mixins.normalizeButton}
  background: transparent;
  font-family: "Tlwg-Typewriter";
  border: 2px dashed white;
  outline: none;
  font-weight: 500;
  width: 100%;
  height: 48px;
  padding: 0 24px;
  font-size: 16px;

  @media (min-width: ${props => props.theme.breakpoints[2]}) {
    margin-right: 0px;
    font-size: 18px;
    height: 66px;
    padding: 0 32px;
  }

  &::placeholder {
    font-family: "Tlwg-Typewriter";
    color: white;
  }
`

const CheckboxContainer = styled.div`
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  left: 0;
  top: 0;
  height: 24px;
`
const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  position: absolute; // take it out of document flow
  opacity: 0; // hide it
`
const StyledCheckbox = styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 6px;
  border: 2px dashed white;
  background: transparent;

  ${props =>
    props.checked
      ? `border: 2px solid white;
        background: white;`
      : null}
`

const Label = styled("label")`
  cursor: pointer;
  position: relative;
  display: block;
  font-weight: 500;

  a {
  }
`

const Signup = styled("button")`
  border: 2px solid white;
  color: black !important;
  width: 100%;
  background: white !important;
  text-align: center;
  font-size: 14px;
  height: 48px;
  padding: 0 20px 0 8px;

  @media (min-width: ${props => props.theme.breakpoints[2]}) {
    font-size: 18px;
    height: 66px;
    padding: 0 20px 0 16px;
  }

  &:disabled {
    opacity: 0.54;
  }
`

const url =
  "https://kulturbolaget.us2.list-manage.com/subscribe/post?u=8382cf200ddf62e8f2004c73e&amp;id=4ba26436c1"

const Checkbox = ({ className, checked, ...props }) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked} />
  </CheckboxContainer>
)

class CustomForm extends Component {
  state = {
    emailValid: false,
    checked: false,
  }

  validateEmail = e => {
    const email = e.target.value
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const isValid = re.test(String(email).toLowerCase())

    if (this.state.emailValid !== isValid) {
      this.setState({
        emailValid: isValid,
      })
    }
  }

  handleCheckboxChange = () => {
    this.setState({ checked: !this.state.checked })
  }

  render() {
    const { status, onValidated } = this.props
    const { emailValid } = this.state

    let email
    const submit = () =>
      email &&
      email.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email.value,
      })

    return (
      <Flex flexDirection="column">
        <Flex
          mt="c"
          width={1}
          flexDirection={["column", null, "column"]}
          alignItems="flex-end"
          fontSize={4}
        >
          <Input
            aria-label="Din e-postadress"
            ref={node => (email = node)}
            type="email"
            onChange={this.validateEmail}
            placeholder="Din e-postadress"
          />
        </Flex>
        <Box mt={["16px", null, "16px"]}>
          <Label>
            <Checkbox
              type="checkbox"
              checked={this.state.checked}
              onChange={this.handleCheckboxChange}
            />
            <Text
              as="div"
              fontWeight="500"
              fontSize={["16px", null, null, "16px"]}
              lineHeight={["1.4", null, null, "1.4"]}
              pt="0px"
              pl={[5, null, null, 5]}
            >
              Jag godkänner att mina uppgifter används enligt följande{" "}
              <a
                href="https://kulturbolaget.se/cookiepolicy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                villkor.
              </a>
            </Text>
          </Label>
        </Box>
        <Box mt={["24px", null, "24px"]}>
          <Signup
            label="Registrera"
            light
            disabled={
              !emailValid || status === "success" || !this.state.checked
            }
            onClick={submit}
          >
            {status === "sending" && "Skickar"}
            {status === "error" && "Något gick fel"}
            {status === "success" && "Registrerad!"}
            {!status && "Prenumerera"}
          </Signup>
        </Box>
      </Flex>
    )
  }
}

const Newsletter = ({ title, text }) => (
  <Box>
    {(title || text) && (
      <Box textAlign="center">
        <span className="newsletter-title">{title}</span>
        <span className="text" dangerouslySetInnerHTML={{ __html: text }} />
      </Box>
    )}
    <MailchimpSubscribe
      url={url}
      render={({ subscribe, status, message }) => (
        <CustomForm
          status={status}
          message={message}
          onValidated={formData => subscribe(formData)}
        />
      )}
    />
  </Box>
)

export default Newsletter
