import React from "react"
import styled from "styled-components"
import SbEditable from "storyblok-react"
import Section from "src/components/UI/Layout/Section"
import Container from "src/components/UI/Layout/Grid/Container"
import Row from "src/components/UI/Layout/Grid/Row"
import Column from "src/components/UI/Layout/Grid/Column"
import H2 from "src/components/UI/Typography/H2"
import TicketLink from "src/storyblok/Components/TicketLink"

const Wrapper = styled(Section)`
  opacity: ${({ hidden }) => (hidden ? 0.54 : 1)};
`

const Date = styled(Column)`
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.3) 66%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: top;
  background-size: 16px 1px;
  background-repeat: repeat-x;

  &:hover + *,
  &:hover {
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.6) 66%,
      rgba(255, 255, 255, 0) 0%
    );
  }
`

const Dates = ({ blok, isEditor }) => {
  const { title, name, list, hidden } = blok
  return !hidden || isEditor ? (
    <SbEditable content={blok}>
      <Wrapper
        hidden={hidden ? hidden : 0}
        justifyContent="center"
        alignItems="center"
        marginY={[6, null, null, 10]}
        id={name ? name : ""}
      >
        <Container>
          <Row justifyContent="center">
            {title && (
              <Column width={[12 / 12, null, null, 10 / 12]}>
                <H2 mb={[6, null, null, 10]}>{title}</H2>
              </Column>
            )}

            {list && list.length > 0 && (
              <Column width={[12 / 12, null, null, 10 / 12]}>
                <Row flexDirection="row">
                  {list.map(blok => (
                    <Date key={blok._uid} width={[12 / 12]}>
                      <TicketLink blok={blok} isEditor={isEditor} />
                    </Date>
                  ))}
                </Row>
              </Column>
            )}
          </Row>
        </Container>
      </Wrapper>
    </SbEditable>
  ) : null
}

export default Dates
