import React from "react"
import styled from "styled-components"
import Flex from "src/components/UI/Layout/Flex"
import Background from "src/components/Background/Background"
import Section from "src/components/UI/Layout/Section"
import Components from "src/storyblok/Components.js"

const Wrapper = styled(Flex)`
  position: relative;
`

const Content = styled.div`
  position: relative;
  z-index: 1;
`

const PageHome = ({ blok, name, isEditor }) => {
  const { header, body, background } = blok

  return (
    <Wrapper flexDirection="column">
      <Content>
        <Section minHeight="100vh" justifyContent="center" alignItems="center">
          {header &&
            header.length > 0 &&
            header.map(blok =>
              React.createElement(Components(blok.component), {
                key: blok._uid,
                blok: blok,
              })
            )}
        </Section>

        {body &&
          body.length > 0 &&
          body.map(blok =>
            React.createElement(Components(blok.component), {
              key: blok._uid,
              blok: blok,
              isEditor: isEditor,
            })
          )}
      </Content>
      {background && <Background background={background} alt={name} />}
    </Wrapper>
  )
}

export default PageHome
