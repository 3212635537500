import styled from "styled-components"
import Heading from "src/components/UI/Typography/Heading"
// import { getScaledFontSize } from "src/helpers/getScaledFontSize"

const H2 = styled(Heading)``

H2.defaultProps = {
  as: "h2",
  lineHeight: "1.1em",
  textAlign: "center",
  fontSize: [
    "28px",
    null,
    null,
    "50px",
    // null,
    // getScaledFontSize("36px", "1600px", 6),
  ],
  letterSpacing: "0px",
}

export default H2
