import React from "react"
import styled from "styled-components"
import Background from "src/components/Background/Background"
import Section from "src/components/UI/Layout/Section"
import Components from "src/storyblok/Components.js"
import H1 from "src/components/UI/Typography/H1"
import Flex from "src/components/UI/Layout/Flex"
import Box from "src/components/UI/Layout/Box"
import Container from "src/components/UI/Layout/Grid/Container"
import Row from "src/components/UI/Layout/Grid/Row"
import Column from "src/components/UI/Layout/Grid/Column"
import Markdown from "src/storyblok/Components/Markdown"
const Wrapper = styled(Flex)`
  position: relative;
`

const Content = styled.div`
  position: relative;
  z-index: 1;
`

const StyledMarkdown = styled.div`
  font-size: 18px;

  @media (min-width: 768px) {
    font-size: 22px;
  }
`

const PageDefault = ({ blok, name, isEditor }) => {
  const { body, background, title, preamble } = blok

  return (
    <Wrapper flexDirection="column">
      <Content>
        <Section
          marginTop={[15, null, null, 20]}
          marginBottom={[6, null, null, 10]}
          justifyContent="center"
          alignItems="center"
        >
          {(title || preamble) && (
            <Container>
              <Row justifyContent="center">
                <Column width={[1, null, null, 10 / 12]}>
                  <Box textAlign={["left", null, null, "center"]}>
                    {title && (
                      <H1
                        mb={preamble ? [6, null, null, 10] : 0}
                        textAlign="center"
                      >
                        {title}
                      </H1>
                    )}
                    {preamble && (
                      <StyledMarkdown>
                        <Markdown blok={{ text: preamble }} />
                      </StyledMarkdown>
                    )}
                  </Box>
                </Column>
              </Row>
            </Container>
          )}
        </Section>

        {body &&
          body.length > 0 &&
          body.map(blok =>
            React.createElement(Components(blok.component), {
              key: blok._uid,
              blok: blok,
              isEditor: isEditor,
            })
          )}
      </Content>
      {background && <Background background={background} alt={name} />}
    </Wrapper>
  )
}

export default PageDefault
