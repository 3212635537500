import styled from "styled-components"
import Heading from "src/components/UI/Typography/Heading"
import { getScaledFontSize } from "src/helpers/getScaledFontSize"

const H1 = styled(Heading)``

H1.defaultProps = {
  as: "h1",
  lineHeight: "1.1em",
  fontSize: [
    "36px",
    null,
    null,
    "64px",
    null,
    getScaledFontSize("64px", "1600px", 6),
  ],
  letterSpacing: "0px",
}

export default H1
