import React from "react"
import { formatImage } from "src/helpers/formatImage"
import isNode from "is-node"

const getSrcSet = (src, ratio, size) => {
  if (!size) {
    return ""
  }
  const array = []
  array.push(`${formatImage(src, size / 4, ratio)} ${size / 4}w`)
  array.push(`${formatImage(src, size / 3, ratio)} ${size / 3}w`)
  array.push(`${formatImage(src, size / 2, ratio)} ${size / 2}w`)
  array.push(`${formatImage(src, size, ratio)} ${size}w`)
  return array.join(", ")
}

// TODO: set srcset from sizes
// Image Component for storyblok images
class Image extends React.Component {
  constructor(props) {
    super(props)
    if (!isNode) {
      this.observer = new IntersectionObserver(this.observeHandler, {
        rootMargin: "0px 0px 200px 0px",
      })
    }
  }

  observeHandler = entries => {
    for (const entry of entries) {
      if (entry.isIntersecting) {
        const src = this.imageRef.getAttribute("data-src") || ""
        const srcSet = this.imageRef.getAttribute("data-srcset") || ""
        this.imageRef.setAttribute("src", src)
        this.imageRef.setAttribute("srcset", srcSet)
        this.imageRef.onload = () => {
          this.imageRef.style.opacity = 1
        }
        this.observer.unobserve(entry.target)
      }
    }
  }

  get placeholder() {
    const { ratio } = this.props
    if (!ratio) {
      // Return an empty pixel
      return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP89fkzEwAIswLkYLzKuwAAAABJRU5ErkJggg=="
    }
    const viewBox = `0 0 ${ratio} 1`
    return `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="${viewBox}"><rect fill="${encodeURIComponent(
      "#FFFFFF00"
    )}" width="100%" height="100%" /></svg>`
  }

  render() {
    let { src, alt, size, ratio, className, isCritical } = this.props

    if (className) {
      className = className + " o-image"
    } else {
      className = "o-image"
    }
    if (isCritical) {
      className = className + " critical"
    }

    const srcSet = getSrcSet(src, ratio, size)
    const formatSrc = formatImage(src, size, ratio)
    let webpChecked = false
    let webpAccepted = false

    if (!isNode && document) {
      webpChecked = document.body.classList.contains("webp-checked")
      webpAccepted = document.body.classList.contains("support-webp")
    }

    return (
      <React.Fragment>
        {isNode && (
          <React.Fragment>
            <noscript>
              <img
                className={className}
                // sizes={sizes}
                srcSet={srcSet}
                src={formatSrc}
                alt={alt}
              />
            </noscript>
            <div />
            <img
              className={className}
              // sizes={sizes}
              data-srcset={srcSet}
              // srcSet={
              //   isCritical ? srcSet : ''
              // }
              data-src={formatSrc}
              src={isCritical ? formatSrc : this.placeholder}
              alt={alt}
            />
          </React.Fragment>
        )}
        {webpChecked &&
          (webpAccepted ? (
            <img
              ref={x => {
                if (x && !isCritical) {
                  this.observer.observe(x)
                  this.imageRef = x
                }
              }}
              className={className}
              data-srcset={srcSet}
              // srcSet={
              //   isCritical ? srcSet : ''
              // }
              srcSet={isCritical ? srcSet : ""}
              data-src={formatSrc}
              src={isCritical ? formatSrc : this.placeholder}
              alt={alt}
            />
          ) : (
            <img
              ref={x => {
                if (x && !isCritical) {
                  this.observer.observe(x)
                  this.imageRef = x
                }
              }}
              className={className}
              data-srcset={srcSet}
              // srcSet={
              //   isCritical ? srcSet : ''
              // }
              srcSet={isCritical ? srcSet : ""}
              data-src={formatSrc}
              src={isCritical ? formatSrc : this.placeholder}
              alt={alt}
            />
          ))}
      </React.Fragment>
    )
  }
}

export default Image
