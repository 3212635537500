import styled from "styled-components"
import Heading from "src/components/UI/Typography/Heading"
// import { getScaledFontSize } from "src/helpers/getScaledFontSize"

const H3 = styled(Heading)``

H3.defaultProps = {
  as: "h3",
  lineHeight: "1.1em",
  textAlign: "left",
  fontSize: [
    "18px",
    null,
    null,
    "22px",
    // null,
    // getScaledFontSize("36px", "1600px", 6),
  ],
  letterSpacing: "0px",
}

export default H3
