import React from "react"
import { Link } from "gatsby"

const LinkComponent = ({
  blok,
  to,
  text,
  className,
  children,
  onClick,
  style,
}) => {
  const { link, text: linkText } = blok ? blok : {}

  const title = text
    ? text
    : linkText
    ? linkText
    : typeof children === "string"
    ? children
    : ""
  const content = children ? children : text ? text : linkText ? linkText : ""

  if (to) {
    if (!to.endsWith("/")) {
      to = to + "/"
    }
    return (
      <Link
        style={style}
        className={className}
        onClick={onClick}
        to={to}
        title={title}
      >
        {content}
      </Link>
    )
  } else {
    let href =
      link?.linktype === "url" && link.url
        ? link.url
        : link?.cached_url
        ? link.cached_url
        : ""

    const isDownload = href.substr(0, 9) === "data:text"
    const isExternal =
      href.substr(0, 4) === "http" ||
      href.substr(0, 7) === "mailto:" ||
      href.substr(0, 4) === "tel:" ||
      isDownload

    if (!isExternal) {
      if (href.startsWith("home")) {
        href = "/"
      }

      if (!href.startsWith("/")) {
        href = "/" + href
      }
    }
    return isExternal ? (
      <a
        rel="noopener noreferrer"
        className={className}
        onClick={onClick}
        title={title}
        target="_blank"
        href={href}
      >
        {content}
      </a>
    ) : (
      <Link
        className={className}
        onClick={onClick}
        title={title}
        to={`${href}`}
      >
        {content}
      </Link>
    )
  }
}

export default LinkComponent
