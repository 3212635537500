import React from "react"
import SbEditable from "storyblok-react"
import styled from "styled-components"
import Section from "src/components/UI/Layout/Section"
import Container from "src/components/UI/Layout/Grid/Container"
import Row from "src/components/UI/Layout/Grid/Row"
import Column from "src/components/UI/Layout/Grid/Column"
import Label from "src/components/UI/Typography/Label"

const Image = styled("img")`
  width: 100%;
  height: auto;
`

const GlobalSeo = ({ blok }) => {
  if (!blok) {
    return null
  }
  return (
    <SbEditable content={blok}>
      <Section minHeight="100vh" justifyContent="center" alignItems="center">
        <Container>
          <Row justifyContent="center">
            <Column width={[12 / 12, null, null, 6 / 10]}>
              <Label mb={[2, null, null, 4]}>{blok.title}</Label>
              <Label mb={[2, null, null, 4]}>{blok.description}</Label>
              {blok.share_image && <Image src={blok.share_image} />}
            </Column>
          </Row>
        </Container>
      </Section>
    </SbEditable>
  )
}

export default GlobalSeo
