import React from "react"
import styled from "styled-components"
import SbEditable from "storyblok-react"

import Box from "src/components/UI/Layout/Box"
import Image from "src/components/Image/Image"
import LinkComponent from "src/components/Link/LinkComponent"

const Wrapper = styled(Box)`
  position: relative;

  span {
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translate(0, 100%);
  }
`

const ImageBlok = ({ blok, size, ratio, linkImage = false }) => {
  const { image, caption } = blok

  const title = caption
    ? caption
    : image && image.title
    ? image.title
    : image && image.alt
    ? image.alt
    : ""
  const alt = image && image.alt ? image.alt : caption ? caption : ""

  return (
    <SbEditable content={blok}>
      {linkImage ? (
        <Wrapper>
          {image && image.filename ? (
            <LinkComponent
              blok={{
                link: { url: image.filename, linktype: "url", cached_url: "" },
              }}
              text={title}
            >
              <Image src={image.filename} alt={alt} size={size} ratio={ratio} />
            </LinkComponent>
          ) : null}
          {caption && <Box as="span">{caption}</Box>}
        </Wrapper>
      ) : (
        <Wrapper>
          {image && image.filename ? (
            <Image src={image.filename} alt={alt} size={size} ratio={ratio} />
          ) : null}
          {caption && <Box as="span">{caption}</Box>}
        </Wrapper>
      )}
    </SbEditable>
  )
}

export default ImageBlok
