import Flex from "src/components/UI/Layout/Flex"
import styled from "styled-components"

const Section = styled(Flex)``

Section.defaultProps = {
  as: "section",
  px: [0, null, null, 0],
}

export default Section
