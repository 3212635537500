import React from "react"
import styled from "styled-components"
import Image from "src/components/Image/Image"

const Background = ({ background, blur, alt, useNoise = false }) => {
  console.log(
    "🚀 ~ file: Background.js ~ line 6 ~ Background ~ background",
    background
  )
  return (
    <Wrapper>
      {background && (
        <Image src={background} alt={alt} ratio={16 / 9} size="2400" />
      )}
    </Wrapper>
  )
}

export default Background

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: fixed;
  z-index: 0;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    object-fit: cover;
  }
`
