import React from "react"
import styled from "styled-components"
import SbEditable from "storyblok-react"
import Label from "src/components/UI/Typography/Label"
import Box from "src/components/UI/Layout/Box"

import LinkComponent from "src/components/Link/LinkComponent"

const Item = styled(Box)`
  display: inline-block;
  padding: 0 8px;
  text-transform: uppercase;
  text-decoration: underline;

  @media (min-width: ${props => props.theme.breakpoints[2]}) {
    padding: 0 16px;
  }
`

const LinkBlok = ({ blok, isEditor, children }) => {
  const { _uid, text } = blok
  return (
    <SbEditable content={blok}>
      {!isEditor ? (
        <Item key={_uid}>
          <LinkComponent blok={blok} title={text}>
            {children ? children : <Label>{text}</Label>}
          </LinkComponent>
        </Item>
      ) : (
        <Item key={_uid}>{children ? children : <Label>{text}</Label>}</Item>
      )}
    </SbEditable>
  )
}

export default LinkBlok
