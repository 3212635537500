import React from "react"
import styled from "styled-components"
import SbEditable from "storyblok-react"
import Section from "src/components/UI/Layout/Section"
import Container from "src/components/UI/Layout/Grid/Container"
import Row from "src/components/UI/Layout/Grid/Row"
import Column from "src/components/UI/Layout/Grid/Column"
import Markdown from "src/storyblok/Components/Markdown"

const Wrapper = styled(Section)`
  opacity: ${({ hidden }) => (hidden ? 0.54 : 1)};
`

const Dates = ({ blok, isEditor }) => {
  const { text, hidden } = blok
  return !hidden || isEditor ? (
    <SbEditable content={blok}>
      <Wrapper
        hidden={hidden ? hidden : null}
        justifyContent="center"
        alignItems="center"
        marginY={[6, null, null, 10]}
        id="information"
      >
        <Container>
          <Row justifyContent="center">
            {text && (
              <Column width={[12 / 12, null, null, 10 / 12]} textAlign="center">
                <Markdown blok={blok}></Markdown>
              </Column>
            )}
          </Row>
        </Container>
      </Wrapper>
    </SbEditable>
  ) : null
}

export default Dates
