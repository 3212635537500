import styled from "styled-components"
import Text from "src/components/UI/Typography/Text"
// import { getScaledFontSize } from "src/helpers/getScaledFontSize"

const Label = styled(Text)``

Label.defaultProps = {
  fontWeight: 500,
  as: "span",
  lineHeight: "1.1em",
  fontSize: ["18px", null, null, "22px"],
  letterSpacing: "0px",
}

export default Label
