import React from "react"
import styled from "styled-components"
import SbEditable from "storyblok-react"

import Box from "src/components/UI/Layout/Box"
import Image from "src/components/Image/Image"
import H3 from "src/components/UI/Typography/H3"

const Wrapper = styled(Box)`
  position: relative;
  text-align: center;

  h3,
  span {
    text-align: center;
  }

  span {
  }
`
const ImageWrapper = styled(Box)`
  padding-bottom: 100%;
  position: relative;
  border-radius: 100%;
  overflow: hidden;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    transition: filter 400ms ease-out;
    filter: grayscale(1);
  }

  @media (min-width: 768px) {
    &:hover img {
      filter: grayscale(0);
    }
  }
`

const Member = ({ blok }) => {
  const { image, title, subtitle } = blok
  return (
    <SbEditable content={blok}>
      <Wrapper>
        {image && image.filename ? (
          <ImageWrapper marginBottom={[2]}>
            <Image
              src={image.filename}
              alt={image.alt}
              width="600"
              ratio={1 / 1}
            />
          </ImageWrapper>
        ) : null}
        {title && <H3>{title}</H3>}
        {subtitle && <Box as="span">{subtitle}</Box>}
      </Wrapper>
    </SbEditable>
  )
}

export default Member
