import React, { Component } from "react"
import SbEditable from "storyblok-react"
import posed from "react-pose"
import styled from "styled-components"
import Flex from "src/components/UI/Layout/Flex"
import Box from "src/components/UI/Layout/Box"
import Label from "src/components/UI/Typography/Label"
import Markdown from "src/storyblok/Components/Markdown"

const Toggle = styled.button`
  width: 100%;
  height: 40px;
  font-size: 18px;
  font-weight: 300;
  text-align: left;
  position: relative;
  padding-right: 40px;

  @media (min-width: 768px) {
    font-size: 25px;
    height: 30px;
  }

  &:focus,
  &:active {
    outline: none;
  }
`

const Icon = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  right: 32px;
  top: 0;
  transition: transform 0.2s linear;

  div {
    background: white;

    &:first-child {
      height: 3px;
      width: 24px;
      top: 50%;
      position: absolute;
      left: 0;
      transform: translateY(-50%);
    }
    &:last-child {
      height: 24px;
      width: 3px;
      left: 50%;
      position: absolute;
      top: 0;
      transform: translateX(-50%);
    }
  }

  &.active {
    transform: rotate(45deg);
  }
`

const Content = posed.div({
  closed: { height: 0, overflow: "hidden" },
  open: { height: "auto", overflow: "hidden" },
})

class Tab extends Component {
  state = {
    open: this.props.isEditor,
  }

  toggleTab = () => {
    this.setState(prevState => ({
      open: !prevState.open,
    }))
  }

  render() {
    const { blok, isEditor } = this.props
    const { title, text } = blok
    const { open } = this.state

    return (
      <SbEditable content={blok}>
        <Flex mb={[2, null, null, 5]} flexDirection="column" width={1}>
          <Box pr={[8, null, null, 8]}>
            <Toggle onClick={this.toggleTab} disabled={isEditor}>
              <Label>{title}</Label>
            </Toggle>
            <Icon className={open ? "active" : ""}>
              <div />
              <div />
            </Icon>
          </Box>
          <Box pr={[8, null, null, 8]}>
            <Content pose={open ? "open" : "closed"}>
              <Box my={4}>{text && <Markdown blok={{ text: text }} />}</Box>
            </Content>
          </Box>
        </Flex>
      </SbEditable>
    )
  }
}

export default Tab
