import React from "react"
import styled from "styled-components"
import SbEditable from "storyblok-react"
import Section from "src/components/UI/Layout/Section"
import Container from "src/components/UI/Layout/Grid/Container"
import Row from "src/components/UI/Layout/Grid/Row"
import Column from "src/components/UI/Layout/Grid/Column"
import Markdown from "src/storyblok/Components/Markdown"
import H2 from "src/components/UI/Typography/H2"
import Box from "src/components/UI/Layout/Box"
import Components from "src/storyblok/Components.js"
const Wrapper = styled(Section)`
  opacity: ${({ hidden }) => (hidden ? 0.54 : 1)};
`

const StyledMarkdown = styled.div`
  font-size: 18px;
  @media (min-width: 768px) {
    font-size: 22px;
  }
`

const Content = ({ blok, isEditor }) => {
  const { title, text, hidden, images } = blok
  return !hidden || isEditor ? (
    <SbEditable content={blok}>
      <Wrapper
        hidden={hidden ? hidden : null}
        justifyContent="center"
        alignItems="center"
        marginY={[6, null, null, 10]}
        id="information"
      >
        <Container>
          <Row
            justifyContent={
              images && images.length > 0 ? "flex-start" : "center"
            }
          >
            {images && images.length > 0 && (
              <Column
                width={[1, null, null, 5 / 12]}
                marginLeft={[0, null, null, (1 / 12) * 100 + "%"]}
              >
                {images &&
                  images.length > 0 &&
                  images.map((blok, index) => {
                    return (
                      <Box
                        key={blok._uid}
                        marginBottom={[
                          6,
                          null,
                          null,
                          index >= images.length - 1 ? 4 : 8,
                        ]}
                      >
                        {React.createElement(Components(blok.component), {
                          blok: blok,
                        })}
                      </Box>
                    )
                  })}
              </Column>
            )}
            {(title || text) && (
              <Column
                width={[
                  1,
                  null,
                  null,
                  images && images.length > 0 ? 5 / 12 : 10 / 12,
                ]}
              >
                <Box
                  textAlign={[
                    "left",
                    null,
                    null,
                    images && images.length > 0 ? "left" : "center",
                  ]}
                >
                  {title && <H2 mb={[6, null, null, 10]}>{title}</H2>}
                  {text && (
                    <StyledMarkdown>
                      <Markdown blok={{ text: text }} />
                    </StyledMarkdown>
                  )}
                </Box>
              </Column>
            )}
          </Row>
        </Container>
      </Wrapper>
    </SbEditable>
  ) : null
}

export default Content
